// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-plant-scanner-js": () => import("./../../../src/pages/plant-scanner.js" /* webpackChunkName: "component---src-pages-plant-scanner-js" */),
  "component---src-pages-plants-js": () => import("./../../../src/pages/plants.js" /* webpackChunkName: "component---src-pages-plants-js" */),
  "component---src-templates-plant-profile-js": () => import("./../../../src/templates/plant-profile.js" /* webpackChunkName: "component---src-templates-plant-profile-js" */)
}

